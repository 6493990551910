.content-block {
	position: relative;
	z-index: 0;
	padding: rem(121px) 0 rem(122px);

	.title {
		margin: 0 0 rem(35px);
	}

	.short-description {
		display: inline-block;
		margin: 0 0 rem(36px);
	}

	> .container {
		margin: 0 auto;

		> .link {
			margin-bottom: rem(48px);
		}
	}

	.content + .links {
		margin-top: 1px;
	}

	.center {
		text-align: center;
	}

	.links {

		&:last-child {
			margin-bottom: rem(38px);
		}
	}

	&:first-of-type {
		padding: rem(250px) 0 rem(123px);
	}

	&:last-child {
		padding: rem(121px) 0 rem(224px);
	}

	&.is-text-block {

		.content-block__content-container {
			padding: rem(75px) 0 0;
		}

		.image {
			margin-top: rem(70px);

			@include breakpoint(l) {
				margin-top: 0;
			}
		}

		.highlighted-list {
			margin-top: rem(50px);

			@include breakpoint(l) {
				margin-top: 0;
			}
		}

		&.show-image-left {

			.column.has-image {

				@include breakpoint(l) {
					order: -1;
				}
			}
		}

		&.has-text-centerd {
			text-align: center;
		}
	}

	&.is-highlighted {
		padding: rem(130px) rem(20px);
		color: #fff;
		margin: 0 0 rem(120px);

		.title {
			color: #fff;

			strong:before {
				background: #fff;
			}
		}

		.content-block__content {
			padding: 0 0 rem(69px);

			@include breakpoint(l) {
				max-width: rem(716px);
				width: 100%;
				padding: 0 0 0 rem(20px);
			}
		}

		.content-block__content-container {

			@include breakpoint(l) {
				max-width: rem(520px);
			}
		}

		.column.has-text {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		.image {

			@include breakpoint("l") {
				max-width: calc(100% - 1.25rem);
			}
		}

		&:before,
		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			@include breakpoint(l) {
				clip-path: polygon(0 0, 75% 0, 50% 100%, 0 100%);
			}
		}

		&:before {
			z-index: -2;
			background: $color-secondary url(../../images/background-element-white.svg) 0 rem(-37px);
			background-size: rem(45px) rem(74px);
		}

		&:after {
			z-index: -1;
			background: linear-gradient(90deg, rgba(29,186,223,1) 0%, rgba(29,186,223,0) 100%);
		}

		&:last-child {
			margin: 0;
		}
	}

	&.is-video {
		text-align: center;

		.content-block__content {
			margin: 0 0 rem(41px);
		}

		.content {
			max-width: rem(670px);
			margin: 0 auto;
		}
	}

	&.is-columns {

		.image {
			max-width: rem(448px);
			margin: rem(70px) auto;

			@include breakpoint(l) {
				margin: 0 auto;
			}
		}

		.content-block__content {
			padding: rem(128px) 0 0;
		}

		.content-block__columns {

			@include breakpoint(t) {
				column-count: 2;
				column-gap: rem(48px);
			}
		}
	}

	&.is-shortcuts-block {
		text-align: center;

		.content-block__content {
			margin: 0 0 rem(41px);
		}

		.shortcuts {
			text-align: left;
		}
	}

	&.is-products-block {
		text-align: center;

		.products {
			text-align: left;
		}
	}

	&.is-form-block {
		background: rgba($color-secondary, 0.1);

		.container {
			position: relative;
		}

		.form-container {

			@include breakpoint("ll") {
				max-width: rem(945px);
			}

			.content {
				margin-bottom: rem(60px);
			}
		}

		.image {
			display: none;
			position: absolute;
			right: rem(-144px);
			bottom: rem(-129px);
			max-width: rem(556px);

			@include breakpoint("ll") {
				display: block;
			}
		}
	}

	&.is-contact-intro {

		.contact-links {
			margin: 0 0 rem(33px);
		}

		.social-media {
			@extend .is-primary;
			margin-bottom: rem(38px);
		}
	}

	&.is-employee-overview {

		.container.is-small {
			text-align: center;
		}

		.quote {
			@include font-quote;
			color: $color-secondary;
		}

		.is-columns {

			@include breakpoint("l") {

				&:nth-child(even) > .columns {

					> .column:nth-child(2) {
						order: -1;
					}
				}
			}
		}
	}

	&.is-news-messages-block {

		.content-block_content {

			@include breakpoint(l) {
				width: 50%;
			}
		}

		.shortcuts {
			margin-top: rem(60px);
		}
	}

	&.is-events-block {

		.container:first-of-type {
			text-align: center;
		}

		.events {
			margin: rem(44px) 0 0;
		}

		&:last-child {
			padding-bottom: rem(200px);
		}
	}

	&.is-feature-block {

		.column {
			display: flex;

			&.is-show-left {

				@include breakpoint(l) {
					order: -1;
				}
			}
		}

		.content-block__content {
			width: 100%;
			padding: rem(153px) rem(20px) rem(50px);

			@include breakpoint(l) {
				max-width: rem(760px);
				padding: rem(153px) rem(20px) 0;
				margin: 0 0 0 auto;
			}
		}

		.content-block__content-container {
			max-width: rem(500px);
		}

		.image {
			margin-bottom: rem(48px);
		}
	}

	&.is-products-block {
		padding-top: rem(350px);

		.products-intro {
			margin-bottom: rem(43px);

			.content {
				margin: auto;
			}
		}

		.products-container {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.swiper-slide {
			width: auto;

			@media screen and (max-width:1024px) {
				max-width: 100%;
			}

			&.product-slide {
				background-color: #e8f8fc;
				min-height: rem(780px);
				text-align: left;
				padding: rem(72px) rem(64px) rem(96px);

				.highlighted-list {
					margin-bottom: rem(48px);
				}
			}
		}

		.swiper-button-next, .swiper-button-prev {
			position: absolute;
			top: 50%;

			@media screen and (max-width:640px) {
				top: 100%;
			}
		}
	}

	&.is-slider-block {
		text-align: center;

		.content {

			@include breakpoint(l) {
				max-width: rem(696px);
				width: 100%;
				margin: 0 auto;
			}
		}

		.swiper-pagination {
			margin: rem(72px) 0 0;
		}

		.buttons {
			margin: 0 0 rem(22px);
		}

		.slide {

			.content {
				max-width: rem(540px);
				margin: 0;
			}
		}
	}

	&.is-testimonial-block {
		text-align: center;

		> .container .content {
			margin: 0 0 rem(35px);
		}

		.slider {
			margin-bottom: rem(48px);
		}
	}

	&.is-categories-block {

		.content-block__content {
			padding: 0 0 rem(69px);
			text-align: center;
			margin: 0 auto;

			@include breakpoint(l) {
				max-width: rem(716px);
				width: 100%;
				padding: 0 0 0 rem(20px);
			}
		}
	}

	&.is-home-highlighted {

		.title {
			margin: 0 0 rem(57px);
		}

		.highlighted-block-item {
			margin: 0 0 rem(39px);
		}
	}

	&.is-news-slider-block {

		.button {
			margin: 0 0 rem(30px);
		}

		.swiper-buttons {
			position: relative;
			z-index: 101;
			display: flex;
			margin: 0 rem(-20px);

			@include breakpoint(l) {
				display: block;
				position: static;
			}
		}

		.column.has-text {
			position: relative;
			z-index: 1;
		}

		.column.has-slider {
			position: relative;
			z-index: 0;
			padding-bottom: rem(56px);
			margin-bottom: rem(48px);

			&:before {
				position: absolute;
				z-index: 100;
				content: '';
				width: 150%;
				height: 100%;
				background: #fff;
				top: 0;
				left: -150%;
			}
		}


		.swiper-button-next,
		.swiper-button-prev {
			top: auto;

			@include breakpoint(l) {
				bottom: 0;
				transform: none;
			}
		}

		.swiper-button-prev {
			@include breakpoint(l) {
				left: 0;
			}
		}

		.swiper-button-next {
			@include breakpoint(l) {
				left: rem(72px);
			}
		}

		.swiper-container {
			@include breakpoint(l) {
				overflow: visible;
			}
		}
	}
}
