@font-face {
	font-family: 'Gotham';
	src: url('../../fonts/Gotham-Book.woff2') format('woff2'), url('../../fonts/Gotham-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Gotham';
	src: url('../../fonts/Gotham-Bold.woff2') format('woff2'), url('../../fonts/Gotham-Bold.woff') format('woff');
	font-weight: Bold;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Gotham';
	src: url('../../fonts/Gotham-Black.woff2') format('woff2'), url('../../fonts/Gotham-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Regular.woff2') format('woff2'), url('../../fonts/Montserrat-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Bold.woff2') format('woff2'), url('../../fonts/Montserrat-Bold.woff') format('woff');
	font-weight: Bold;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Black.woff2') format('woff2'), url('../../fonts/Montserrat-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'AvenirLTStd';
	src: url('../../fonts/AvenirLTStd-Book.woff2') format('woff2'), url('../../fonts/AvenirLTStd-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'AvenirLTStd';
	src: url('../../fonts/AvenirLTStd-Light.woff2') format('woff2'), url('../../fonts/AvenirLTStd-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'AvenirLTStd';
	src: url('../../fonts/AvenirLTStd-Black.woff2') format('woff2'), url('../../fonts/AvenirLTStd-Black.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@mixin font-text {
	font-family: $font-text;
	font-size: rem(18px);
	line-height: rem(24px);

	@media only screen and (min-width: 432px) {
		font-size: rem(17px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(16px);
	}
}

@mixin font-legal {
	font-size: rem(12px);
	line-height: rem(24px);
}

@mixin font-short-description {
	font-size: rem(14px);
	line-height: rem(24px);
	letter-spacing: 0.05em;
}

@mixin font-quote {
	font-size: rem(24px);
	line-height: rem(32px);
}

@mixin font-title-caption {
	font-family: $font-title;
	font-size: rem(14px);
	line-height: rem(24px);
}

@mixin font-title-button {
	font-family: $font-title;
	font-size: rem(16px);
	line-height: rem(24px);
	letter-spacing: 0.05em;
}

@mixin font-title-contact {
	font-family: $font-title;
	font-size: rem(16px);
	line-height: rem(24px);
}

@mixin font-sub-title {
	font-family: $font-title;
	font-size: rem(18px);
	line-height: rem(24px);
}

@mixin font-title-smaller {
	font-family: $font-title;
	font-size: rem(24px);
	line-height: rem(32px);
	letter-spacing: 0.05em;
}

@mixin font-title {
	font-family: $font-title;
	font-size: rem(30px);
	line-height: rem(33px);

	@include breakpoint(t) {
		font-size: rem(36px);
		line-height: rem(40px);
	}
}

@mixin font-title-big {
	font-family: $font-title;
	font-size: rem(32px);
	line-height: rem(32px);
	letter-spacing: 0.05em;

	@include breakpoint(t) {
		font-size: rem(40px);
		line-height: rem(47px);
	}

	@include breakpoint(l) {
		font-size: rem(48px);
		line-height: rem(56px);
	}
}

@mixin font-title-hero {
	font-family: $font-title;
	font-size: rem(42px);
	line-height: rem(42px);
	letter-spacing: 0.05em;

	@include breakpoint(t) {
		font-size: rem(56px);
		line-height: rem(56px);
	}

	@include breakpoint(l) {
		font-size: rem(64px);
		line-height: rem(64px);
	}
}