.faq-block {

	h2.title,
	.short-description {
		width: 100%;
		text-align: center;
	}

	.column {
		padding-top: 24px;
		padding-bottom: 24px;
	}

	h3.title {
		margin: 0 0 rem(8px);
	}
}
