﻿%button {
	@include font-title-button;
	position: relative;
	z-index: 0;
	display: inline-flex;
	border: none;
	padding: rem(16px) rem(50px) rem(16px) rem(24px);
	border-radius: $border-radius;
	margin: 0;
	text-decoration: none;
	color: #fff;
	background: $color-primary;
	overflow: hidden;
	cursor: pointer;
	font-weight: bold;
	transition: background $transition-time ease-in-out, color $transition-time ease-in-out;
	letter-spacing: 0;

	&:after {
		@include icon($icon-arrow-right);
		position: absolute;
		top: rem(21px);
		right: rem(26px);
		font-size: rem(16px);
		transition: right $transition-time ease-in-out;
	}

	&.is-secondary {
		background: $color-secondary;
	}

	&.has-arrow-down {

		&:after {
			@include icon($icon-arrow-down);
			transition: top $transition-time ease-in-out;
		}
	}

	&.has-expand {
		padding: rem(13px) rem(24px) rem(15px) rem(52px);

		&:after {
			@include icon($icon-expand);
			top: rem(18px);
			left: rem(28px);
			right: auto;
		}
	}

	&.is-borderd {
		border: 2px solid $color-primary;
		background: #fff;
		color: $color-primary;

		&.is-white {
			border-color: #fff;
			background: transparent;
			color: #fff;
		}

		&.is-white-l {

			@include breakpoint(l) {
				border-color: #fff;
				background: transparent;
				color: #fff;
			}
		}
	}

	&.is-download {
		padding: rem(16px) rem(24px) rem(16px) rem(50px);

		&:after {
			@include icon($icon-download);
			right: auto;
			left: rem(26px);
		}
	}

	&.is-loading {
		padding-left: 60px;

		&:after {
			position: absolute;
			display: block;
			content: '';
			border: rem(4px) solid $color-secondary;
			border-radius: 50%;
			border-top: rem(4px) solid lighten($color-secondary, 10%);
			width: rem(20px);
			height: rem(20px);
			top: rem(16px);
			left: rem(16px);
			animation: spin 1.5s linear infinite;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	&:focus {
		@extend .button-focus;
	}

	&:hover {
		@extend .button-hover;
	}
}

.button {
	@extend %button;
}

.button-focus {

	&:after {
		right: rem(22px);
	}

	&.is-borderd {
		background: $color-primary;
		color: #fff;

		&.is-white {
			background: #fff;
			color: $color-primary;
		}

		&.is-white-l {

			@include breakpoint(l) {
				background: #fff;
				color: $color-primary;
			}
		}
	}
}

.button-hover {
	background: mix($color-secondary, $color-primary);

	&:after {
		right: rem(22px);
	}

	&.is-borderd {
		background: $color-primary;
		color: #fff;

		&.is-white {
			background: #fff;
			color: $color-primary;
		}
	}

	&.has-arrow-down {

		&:after {
			top: rem(24px);
			right: rem(26px);
		}
	}

	&.has-expand {

		&:after {
			left: rem(28px);
			right: auto;
		}
	}

	&.is-download {

		&:after {
			right: auto;
			left: rem(26px);
		}
	}
}

.buttons {
	margin: rem(-10px);

	.button,
	.video-link {
		margin: rem(10px);
	}
}
