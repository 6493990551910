.call-to-action-image {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 0;
	height: rem(600px);
	padding: rem(90px) 0 rem(96px);
	margin: 0 0 rem(120px);
	background: #000;

	.call-to-action-image__content {
		position: relative;
		z-index: 1;
		text-align: center;
		color: #fff;

		.title {
			color: #fff;
			margin: 0 0 rem(32px);
		}

		.content {
			color: #fff;
			margin: 0 0 rem(32px);
		}
	}

	.call-to-action-image__image {
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
		opacity: 0.6;
	}

	.container {
		margin: 0 auto;
	}

	&:last-of-type {
		margin: 0;
	}
}
