﻿@import "fonts";
@import "icon";

$font-text: "AvenirLTStd", sans-serif;
$font-title: "Gotham", "Montserrat", $font-text;

$color-primary: #003c68;
$color-secondary: #1dbadf;
$color-special: #00b197;
$color-border: #ccd8e1;

$color-text: #4a4a49;

$transition-time: 0.3s;

$border-radius: rem(28px);

$grid-gap: rem(48px);