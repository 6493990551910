label {
	font-weight: 700;
}

.hbspt-form {
	text-align: left;
}

.hs-form-field {

	label,
	.label {
		display: block;
		margin: 0 0 rem(5px);
		color: $color-primary;
		font-weight: bold;
	}

	.hs-input {
		background: #fff;
		width: 100%;
		min-height: rem(46px);
		padding: 0 rem(20px);
		border: 1px solid rgba($color-primary, 0.1);
		margin: 0 0 rem(20px);
		overflow: hidden;
		color: #000;
		transition: all $transition-time ease-in-out;
		line-height: rem(46px);
		appearance: none;

		@include breakpoint(t) {
			min-height: rem(48px);
		}

		&:focus {
			border-color: $color-primary;
			outline: none;
		}

		&[type=file] {
			padding-top: rem(12px);
			padding-bottom: rem(12px);
		}
	}
}


.hs-fieldtype-textarea {
	.hs-input {
		min-height: rem(96px);

		@include breakpoint(t) {
			min-height: rem(160px);
		}
	}
}

.hs-fieldtype-radio {
	.inputs-list {
		padding: 0;
		list-style: none;

		&.multi-container {
			@include breakpoint(t) {
				column-count: 2;
				column-gap: $grid-gap;
			}
		}

		.hs-form-radio {
			.hs-form-radio-display {
				input {
					width: auto !important;
				}
			}

			label:not(.hs-error-msg) {
				position: relative;
				display: inline-block;
				padding-left: rem(40px);
				margin: 0 0 rem(8px);
				color: $color-text;
				cursor: pointer;
				font-weight: 400;

				input.hs-input {
					position: absolute;
					top: 0;
					left: 0;
					width: 0 !important;
					margin: 0;
					opacity: 0;

					&:checked {
						~ span {
							&::before {
								border-width: 3px;
								background-color: $color-primary;
							}
						}
					}
				}

				> span {
					margin-left: 0 !important;

					&::before {
						position: absolute;
						top: rem(4px);
						left: 0;
						width: rem(20px);
						height: rem(20px);
						border: 1px solid rgba($color-primary, 0.1);
						background: #fff;
						content: '';
					}
				}
			}
		}
	}
}

.hs-fieldtype-select {
	position: relative;
	display: block;
	max-width: 100%;
	vertical-align: top;

	&::after {
		@include icon($icon-arrow-down);
		position: absolute;
		top: rem(40px);
		right: rem(20px);
		color: $color-primary;
		pointer-events: none;
		transition: color 150ms ease-in-out;

		@include breakpoint(t) {
			top: rem(44px);
		}
	}

	&:hover::after,
	&:focus::after {
		color: $color-secondary;
	}

	select {
		position: relative;
		display: block;
		width: 100%;
		padding: rem(12px) rem(60px) rem(12px) rem(20px);
		border: 0;
		appearance: none;
		line-height: inherit;
		outline: none;
	}
}

.hs-fieldtype-booleancheckbox {
	margin: rem(10px) 0 rem(10px);

	.input {
		.inputs-list {
			padding: 0;
			margin: 0;
			list-style: none;
		}
	}

	label:not(.hs-error-msg) {
		position: relative;
		display: inline-block;
		padding-left: rem(40px);
		margin: 0 0 0.5rem;
		color: $color-text;
		cursor: pointer;
		font-weight: 400;

		input.hs-input {
			position: absolute;
			top: 0;
			left: 0;
			width: 0 !important;
			margin: 0;
			opacity: 0;

			&:checked {
				~ span {
					&::before {
						border-width: 3px;
						border-color: #fff;
						outline: 1px solid rgba($color-primary, 0.1);
						background-color: $color-primary;
					}
				}
			}
		}

		> span {
			margin-left: 0 !important;

			&::before {
				position: absolute;
				top: rem(2px);
				left: 0;
				width: rem(20px);
				height: rem(20px);
				border: 1px solid rgba($color-primary, 0.1);
				background: #fff;
				content: '';
			}
		}
	}
}

.form-columns-1,
.form-columns-2 {
	max-width: none !important;

	.input {
		margin-right: 0 !important;
	}

	.hs-input {
		width: 100% !important;
	}
}

.form-columns-2 {
	margin: 0 ($grid-gap / -4) !important;

	@include breakpoint(t) {
		display: flex;
	}

	@include breakpoint(l) {
		margin: 0 ($grid-gap / -2) !important;
	}

	> .hs-form-field {
		width: 100% !important;
		flex: 1 1 0;
		padding: 0 ($grid-gap / 4);

		@include breakpoint(t) {
			width: 50% !important;
		}

		@include breakpoint(l) {
			padding: 0 ($grid-gap / 2);
		}
	}
}

ul.hs-error-msgs {
	padding: 0.5rem 1.25rem;
	border: 1px solid #f2545b;
	background: mix(#fff, #f2545b, 75%);
	border-radius: 0;
	margin: 0 0 rem(20px);
	color: #f2545b;
	font-weight: normal;
	list-style: none;
}

.hs-error-msg {
	color: #f2545b !important;
}

.hs-submit {
	.actions {
		text-align: right;
	}
}

.hs-button {
	@extend %button;
	padding-right: rem(24px);
	transition: background-color 250ms ease-in-out;
}
