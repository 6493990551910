$icon-arrow-down: '\ea01';
$icon-arrow-left: '\ea02';
$icon-arrow-right: '\ea03';
$icon-arrow-up: '\ea04';
$icon-check: '\ea05';
$icon-deviation: '\ea06';
$icon-download: '\ea07';
$icon-expand: '\ea08';
$icon-globe: '\ea09';
$icon-insight: '\ea0a';
$icon-list-icon: '\ea0b';
$icon-login: '\ea0c';
$icon-mail: '\ea0d';
$icon-package: '\ea0e';
$icon-phone: '\ea0f';
$icon-play: '\ea10';
$icon-prediction: '\ea11';
$icon-quote-left: '\ea12';
$icon-quote-right: '\ea13';
$icon-facebook: '\ea14';
$icon-instagram: '\ea15';
$icon-linkedin: '\ea16';
$icon-pinterest: '\ea17';
$icon-twitter: '\ea18';
$icon-vimeo: '\ea19';
$icon-youtube: '\ea1a';

@at-root {
	@font-face {
		font-family: icon;
		
		src: url('../../fonts/icon.woff2') format('woff2'), url('../../fonts/icon.woff') format('woff'); 
		font-style: normal;
		font-weight: normal;
		font-display: block;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	vertical-align: middle;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before {
	@include icon;
}

.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-up:before { content: $icon-arrow-up; }
.icon-check:before { content: $icon-check; }
.icon-deviation:before { content: $icon-deviation; }
.icon-download:before { content: $icon-download; }
.icon-expand:before { content: $icon-expand; }
.icon-globe:before { content: $icon-globe; }
.icon-insight:before { content: $icon-insight; }
.icon-list-icon:before { content: $icon-list-icon; }
.icon-login:before { content: $icon-login; }
.icon-mail:before { content: $icon-mail; }
.icon-package:before { content: $icon-package; }
.icon-phone:before { content: $icon-phone; }
.icon-play:before { content: $icon-play; }
.icon-prediction:before { content: $icon-prediction; }
.icon-quote-left:before { content: $icon-quote-left; }
.icon-quote-right:before { content: $icon-quote-right; }
.icon-facebook:before { content: $icon-facebook; }
.icon-instagram:before { content: $icon-instagram; }
.icon-linkedin:before { content: $icon-linkedin; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-twitter:before { content: $icon-twitter; }
.icon-vimeo:before { content: $icon-vimeo; }
.icon-youtube:before { content: $icon-youtube; }
