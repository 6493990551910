.testimonial-blocks {

	.container.is-small {
		text-align: center;

		.title {
			margin: 0 0 rem(48px);
		}
	}

	.testimonial-block {

		.testimonial-block__content {
			padding: rem(16px) 0 rem(32px);
			text-align: center;
		}

		.title {
			margin: 0 0 rem(8px);
		}
	}

	figure.is-logo {
		max-width: 280px;
		margin: 0 auto;
	}
}
