.header-registration {
	position: relative;
	z-index: 0;
	padding: rem(20px) 0 0;
	margin: 0 0 rem(40px);
	color: $color-primary;
	//background: rgba($color-secondary, 0.1);

	@include breakpoint(t) {
		padding: rem(80px) 0 0;
		margin: 0 0 rem(80px);
	}

	@include breakpoint(l) {
		padding: rem(240px) 0 0;
		margin: 0 0 rem(120px);
		background: #fff;
	}

	&:before {
		position: absolute;
		content: '';
		z-index: -1;
		width: 100%;
		height: calc(100% + 8.625rem);
		top: -8.625rem;
		left: 0;
		background: $color-secondary;
		transition: all .5s ease-in-out;
		opacity: 0.1;

		@include breakpoint(l) {
			height: 100%;
			top: 0;
			clip-path: polygon(0 0, 72% 0, 45% 100%, 0 100%);
		}
	}

	.container {
		margin: 0 auto;

		> .columns > .column {
			margin: rem(24px) 0;
		}
	}

	.content {
		@include font-sub-title;
	}

	h1 {
		margin: 0 0 rem(32px);
	}

	.image {
		margin: rem(48px) 0 0;

		@include breakpoint(l) {
			position: relative;
			bottom: rem(-96px);
		}
	}

	.header-registration__form {
		background: #fafafa;
		padding: rem(24px);
		border-radius: rem(8px);
		overflow: hidden;
		box-shadow: 4px 7px 8px 4px rgba(0, 60, 104, .2);

		@include breakpoint(t) {
			padding: rem(38px);
		}

		.title {
			text-align: center;
			margin: 0 0 rem(32px);
		}

		label {
			color: $color-text;

			span {
				color: #f3131d;
			}
		}

		textarea {
			min-height: rem(160px);
		}

		.form-button-container .button {
			float: left;
		}
	}
}
