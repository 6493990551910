﻿.select {
	position: relative;

	select {
		background: #fff;
		width: 100%;
		min-height: rem(46px);
		padding: 0 rem(20px);
		border: 1px solid rgba($color-primary, 0.1);
		overflow: hidden;
		color: #000;
		transition: all $transition-time ease-in-out;
		line-height: rem(46px);
		-webkit-appearance: none;

		&:focus {
			border-color: rgba($color-primary, 0.25);
		}
	}

	&:after {
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: rem(4px) rem(4px) 0 rem(4px);
		border-color: $color-primary transparent transparent transparent;
		position: absolute;
		top: rem(21px);
		right: rem(16px);
		font-size: rem(9px);
		pointer-events: none;
	}
}
