﻿.check-list {
	//@include font-sub-title;
	font-size: 1.125rem;
	line-height: 1.5rem;
	list-style: none;
	padding: rem(16px) 0 0 0;

	li {
		position: relative;
		padding: 0 0 0 rem(48px);
		margin: 0 0 rem(16px);

		&:before {
			@include icon($icon-check);
			position: absolute;
			width: rem(26px);
			height: rem(26px);
			border: 2px solid $color-secondary;
			border-radius: 50%;
			top: rem(-2px);
			left: rem(0);
			font-size: rem(12px);
			line-height: rem(25px);
			text-align: center;
			color: $color-secondary;
		}
	}
}
