.benefits-block {

	h2.title,
	.short-description {
		width: 100%;
		text-align: center;
	}

	h3.title {
		margin: 0 0 rem(8px);
	}

	.column {
		padding-top: 24px;
		padding-bottom: 24px;
	}

	.benefit {
		position: relative;
		padding: 0 0 0 rem(60px);

		&:before {
			@include icon($icon-package);
			position: absolute;
			top: 0;
			left: 0;
			font-size: rem(40px);
		}
	}
}
